import { Scene } from 'phaser'
import { TextButton } from '@/games/lib/text-button';

export default class EndScene extends Scene {
  constructor () {
    super({ key: 'EndScene' })
  }
  preload() {
    this.load.image('bg', '/static/games/game1/bg.jpg');
    this.load.svg('replay', '/static/games/icon_replay.svg', {width:50, height:50});
    this.load.svg('next', '/static/games/icon_next.svg', {width:50, height:50});
  }

  init(data) {
    this.score = data.score;
    this.isWin = data.isWin;
  }

  create () {    
    const bg = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'bg')

    var graphics = this.add.graphics();
    graphics.fillGradientStyle(0x000000, 0x000000, 0x000000, 0x000000, 0.7,0.7,1,1);
    graphics.fillRect(0, 0, this.cameras.main.width, this.cameras.main.height);

    let wintext = '';
    if(this.isWin) {
      //wintext = "ผ่านเกมแล้ว" 
    } else {
      //wintext = "ไม่ผ่านเกม" 
    }
    wintext += "คะแนน: "+this.score
    
    var scoreBox = this.add.graphics();
    scoreBox.fillStyle(0xFFAD02, 1).fillRoundedRect(this.cameras.main.centerX-300, this.cameras.main.centerY-200, 600, 140, 70);

    let scoreText = this.add.text(this.cameras.main.centerX, this.cameras.main.centerY-130, wintext, { fontFamily: "WF Prompt", fontSize: 70, color: '#ffffff', fontWeight: 'bold' }).setOrigin(0.5);
    scoreText.setShadow(1,1,'#888888',5,false,true)

    this.replayButton = new TextButton(this, this.cameras.main.centerX-500, this.cameras.main.centerY+100, 400,100, 'เล่นอีกครั้ง', null, 'replay', () => {this.scene.start('PlayScene')});
    this.add.existing(this.replayButton)


    this.nextButton = new TextButton(this, this.cameras.main.centerX+100, this.cameras.main.centerY+100, 400,100, 'บทเรียนต่อไป', null, 'next', () => {this.game.events.emit('done',true)});
    this.add.existing(this.nextButton)

    this.game.events.emit('done',false);
  }
  clickTarget() {
    this.scene.scene.start('PlayScene')
  }
  update() {
    //this.helloWorld.angle += 1;
  }
}

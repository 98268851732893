import { Scene } from 'phaser'

export default class BootScene extends Scene {
  constructor () {
    super({ key: 'BootScene' })
  }
  preload() {
    this.cameras.main.setBackgroundColor("#222");
    this.load.image('startbg', '/static/games/game1/waitingbg.png');
    this.load.image('startbutton', '/static/games/base_finder/start_btn.png');
  }
  create () {
    this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'startbg')
    const startbutton = this.add.image(this.cameras.main.centerX, 800, 'startbutton')
    startbutton.setInteractive({ useHandCursor: true })
    startbutton.on('pointerup', this.clickTarget);
  }
  clickTarget() {
    this.scene.scene.start('PlayScene')
  }
}

import Phaser from 'phaser'
import PlayScene from './PlayScene'
import BootScene from './BootScene'
import EndScene from './EndScene'

function launch(containerId) {
  return new Phaser.Game({
    type: Phaser.AUTO,
    parent: containerId,
    scale: {
      width: 1600,
      height: 900,
      mode: Phaser.Scale.FIT,
      autoCenter: Phaser.Scale.CENTER_BOTH,
      fullscreenTarget: containerId
    },
    dom: {
      createContainer: true
    },
    fps: {
      target: 24,
      forceSetTimeOut: true
    },
    physics: {
      default: 'arcade'
    },
    scene: [BootScene, PlayScene, EndScene]
  })
}
export default launch
export { launch }

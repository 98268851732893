import Phaser from 'phaser'
import BaseScene from '../BaseScene';
import _, { first } from 'lodash';
import { TextButton } from '@/games/lib/text-button';

export default class PlayScene extends BaseScene {
  constructor () {
    super({ key: 'PlayScene' })
  }

  preload () {
    this.load.image('bg', '/static/games/game1/bg.jpg');
    this.load.audio('bg_audio', '/static/games/game1/bg.mp3');

    if (this.sys.game.device.os.desktop){
      this.load.spritesheet('bg_anim', '/static/games/game1/bg_anim.png',{ frameWidth: 1600, frameHeight: 900 });
    } else {
      this.load.spritesheet('bg_anim', '/static/games/game1/bg_noanim.png',{ frameWidth: 1600, frameHeight: 900 });
    }

    this.load.audio('bang_air', '/static/games/game1/bang_air.mp3');
    this.load.audio('ended', '/static/games/game1/ended.mp3');
    this.load.audio('miss', '/static/games/game1/miss.mp3');
    this.load.audio('correct', '/static/games/game1/correct.mp3');
    this.load.audio('wrong', '/static/games/game1/wrong.mp3');

    this.load.image('header_bg', '/static/games/game1/black.png');
    this.load.image('bar_bg', '/static/games/game1/time1.png');
    this.load.image('clock', '/static/games/game1/clock.png');

    this.load.spritesheet('bang_correct', '/static/games/game1/2-star.png',{ frameWidth: 200, frameHeight: 200 });
    this.load.spritesheet('bang_heal', '/static/games/game1/3-heal.png',{ frameWidth: 200, frameHeight: 200 });
    this.load.spritesheet('bang_wrong', '/static/games/game1/4-wrong.png',{ frameWidth: 200, frameHeight: 200 });
    
    this.load.spritesheet('target', '/static/games/game1/1-target.png',{ frameWidth: 200, frameHeight: 200 });
    this.load.image('char1', '/static/games/game1/objects/correct/prep.png');
    this.load.image('heart', '/static/games/game1/heart.png');

    this.file_corrects = ['condom','goodsyringe','mask','prep'];
    this.file_corrects.map((item)=>{
      return this.load.image('c_'+item, '/static/games/game1/objects/correct/'+item+'.png');
    })
    this.file_wrongs = ['badsyringe','sharesyringe','sneeze'];
    this.file_wrongs.map((item)=>{
      return this.load.image('w_'+item, '/static/games/game1/objects/wrong/'+item+'.png');
    })

    this.load.svg('fullscreen', '/static/games/fullscreen.svg', {width:80, height:80});
    this.loadingProgress();
  }
  
  create () {
    const bg = this.add.image(this.cameras.main.centerX, this.cameras.main.centerY, 'bg')
    this.bg_audio = this.sound.add('bg_audio', {volume: 0.5});
    this.bg_audio.setLoop(true).play();
    this.bg_anim = this.add.sprite(this.cameras.main.centerX, this.cameras.main.centerY, 'bg_anim')
    this.anims.create({
      key: 'play',
      frames: this.anims.generateFrameNumbers('bg_anim', { frames: this.genFrame(1,14) }),
      frameRate: 8,
      repeat: -1
    });
    this.bg_anim.play('play')

    this.air_sound = this.sound.add('bang_air').setLoop(false);
    this.miss_sound = this.sound.add('miss').setLoop(false);
    this.correct_sound = this.sound.add('correct').setLoop(false);
    this.wrong_sound = this.sound.add('wrong').setLoop(false);
    this.audio_end = this.sound.add('ended').setLoop(false);

    const header_bg = this.add.image(0, 0, 'header_bg').setOrigin(0)
    const bar_bg = this.add.image(45, 45, 'bar_bg').setOrigin(0)
    this.bar = this.add.graphics();
    
    //const bar = this.add.image(45, 45, 'bar').setOrigin(0)
    const clock = this.add.image(1010, -7, 'clock').setOrigin(0)
    
    const fullscreen = this.add.image(this.cameras.main.width-70, 70, 'fullscreen')
    fullscreen.setInteractive({ useHandCursor: true }).on('pointerdown', () => {
      this.scale.toggleFullscreen();
    }).setDepth(2);
    
    this.target = this.add.sprite(this.cameras.main.centerX+130, this.cameras.main.height-100, 'target').setDepth(2);
    this.anims.create({
      key: 'blink',
      frames: this.anims.generateFrameNumbers('target', { frames: this.genFrame(1,14) }),
      frameRate: 16,
      repeat: 0
    });

    this.anims.create({
      key: 'anim_heal',
      frames: this.anims.generateFrameNumbers('bang_heal', { frames: this.genFrame(1,14) }),
      frameRate: 16,
      repeat: 0
    });
    this.anims.create({
      key: 'anim_correct',
      frames: this.anims.generateFrameNumbers('bang_correct', { frames: this.genFrame(1,14) }),
      frameRate: 24,
      repeat: 0
    });
    this.anims.create({
      key: 'anim_wrong',
      frames: this.anims.generateFrameNumbers('bang_wrong', { frames: this.genFrame(1,4) }),
      frameRate: 8,
      repeat: 0
    });

    this.input.keyboard.addKey(Phaser.Input.Keyboard.KeyCodes.SPACE);
    this.input.keyboard.on('keydown_SPACE',  (event)=> {
      this.kill()
    });
    this.input.on('pointerup',  (pointer) => {
      this.kill()
    });

    this.hearts = []
    for(var i=0;i<4;i++) {
      let x = 1183 + (i*85)
      let y = 655 - (i*19)
      this.hearts.push( this.add.image(x, y, 'heart').setOrigin(0) )
    }
    this.setHealth()

    this.timedEvent = this.time.delayedCall(this.timer, this.timeOut, [], this);
    this.timedText = this.add.text(550, 53, 'Timer', { fontFamily: 'WF Prompt', fontSize: 30, color: '#000000' });

    this.scoreTitleText = new TextButton(this, 1150, 25, 340,90, 'คะแนน: 0', null, null, null);
    this.add.existing(this.scoreTitleText)

    this.currents = []
    this.addObj()
  }

  addObj() {
    let no = Math.floor(Math.random() * (this.file_corrects.length+this.file_wrongs.length))
    let load = null
    let isCorrect = false
    if(no < this.file_corrects.length) {
      load = 'c_'+this.file_corrects[no]
      isCorrect = true
    } else {
      no = no-this.file_corrects.length
      load = 'w_'+this.file_wrongs[no]
      isCorrect = false
    }
    let obj = this.add.image(this.cameras.main.centerX+130, 100, load)
    obj.isCorrect = isCorrect
    obj.isHit = false
    this.currents.push(obj)

    this.time.addEvent({
      delay: this.delay,
      loop: false,
      callback: ()=>{this.addObj()}
    })
  }

  setHealth() {
    if(this.life<=0){
      this.gameEnded()
    } else {
      this.hearts.map((item,index)=>{
        if(index>=this.life)item.setVisible( false )
        else item.setVisible( true )
      })
    }
  }

  update () {
    this.currents = this.currents.map((obj)=>{
      if(!obj.isHit) {
        obj.y+=this.speed;
        if (obj.y > this.sys.game.scale.gameSize.height) {
          if(obj.isCorrect) {
            this.life--
            this.air_sound.play()
            this.setHealth()
          }
          obj.destroy()
          return null
        } else {
          return obj
        }
      }
    }).filter((item)=>{
      if(item) {
        return true
      } else {
        return false
      }
    })

    let progress = this.timedEvent.getProgress()
    let timeLeft = ((this.timer - this.timedEvent.delay*progress)/1000).toFixed(0);
    let width = 1000-Math.round(progress*1000)+30
    this.bar.clear()
    if(progress < 0.8) {
      this.bar.fillStyle(0x79C962, 1)
    } else {
      this.bar.fillStyle(0xFC6801, 1)
    }
    this.bar.fillRoundedRect(55, 55, width, 30, 15);
    if(progress === 1) {
      this.gameEnded()
    }
    let seconds = Math.floor(timeLeft % 60);
    let minutes = Math.floor(timeLeft / 60);
    let timeFormatted = (this.zeroPad(minutes,2)+":"+this.zeroPad(seconds,2));
    this.timedText.setText(timeFormatted);
  }

  gameEnded() {
    this.scene.pause();
    this.audio_end.play()
    this.bg_audio.pause()
    setTimeout(()=>{
      this.ended = true
      this.scene.start('EndScene',{score:this.score, isWin:false})
    },600)
  }

  kill() {
    if(this.hitting)return
    this.hitting = true
    setTimeout(()=>{this.hitting=false},300)
    this.target.play('blink')
    let hit = false
    this.currents.map((obj)=>{
      if (_.inRange((obj.y+obj.height/2),this.target.y, this.target.y+this.target.height)) {
        this.hit(obj);
        hit = true
      }
    });
    if(!hit){
      this.miss();
    }
  }

  hit(obj) {
    let bang = null;
    if(obj.isCorrect) {
      this.score++;
      this.correct_sound.play();
      if(obj.texture.key==='c_prep') {
        bang = this.add.sprite(obj.x, obj.y, 'bang_heal').setDepth(3);
        bang.play('anim_heal')
        if(this.life<4)this.life++;
      } else {
        bang = this.add.sprite(obj.x, obj.y, 'bang_correct').setDepth(3);
        bang.play('anim_correct')
      }
    } else {
      //this.score--;
      this.wrong_sound.play();
      bang = this.add.sprite(obj.x, obj.y, 'bang_wrong')
      bang.play('anim_wrong')
      if(this.life>0)this.life--;
    }
    obj.isHit = true
    this.tweens.add({
      targets: obj,
      alpha: 0,
      duration: 300,
      ease: 'Power2',
      onComplete:  ()=> {obj.destroy()},
    }, this);
    bang.once('animationcomplete',()=>{bang.destroy()});
    this.speed = (this.speed<28)?this.initSpeed+Math.round(this.score/3):this.speed;
    this.delay = (this.delay>400)?this.initDelay-(this.score*30):this.delay;
    this.scoreTitleText.setText('คะแนน: '+this.score)
    this.setHealth()
  }

  miss() {
    this.miss_sound.play();
  }

  timeOut () {

  }

  init(data) {
    this.hitting = false
    this.initDelay = 1200
    this.initSpeed = 16
    this.ended = false
    this.timer = 60000
    this.score = 0
    this.speed = this.initSpeed
    this.delay = this.initDelay
    this.life = 3
  }

  zeroPad(number,size){
    var stringNumber = String(number);
    while(stringNumber.length < (size || 2)){
      stringNumber = "0" + stringNumber;
    }
    return stringNumber;
  }

  genFrame(row,count) {
    let result = []
    let from = ((row-1)*13) 
    let to = from+(count-1)
    for(var i=from;i<=to;i++) {
      result.push(i)
    }
    return result
  }
}
